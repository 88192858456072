<template>
  <div class="tab-pane active" id="tabs-1" role="tabpanel">
    <div class="lesiurpackages_box" v-if="dealListItem.code === 'domestic'">
      <div class="row" v-for="(rowItem, rowIndex) in rowGroup" :key="rowIndex">
        <div class="col-lg-8" v-if="rowIndex % 2 === 0">
          <div class="row">
            <DomesticTabItem :items="rowItem[0]" :isBig="!!rowItem[1]" />
          </div>
        </div>

        <div :class="rowItem.length < 3 ? 'col-lg-8' : 'col-lg-4'" v-else>
          <div class="row">
            <DomesticTabItem :items="rowItem[0]" />
            <DomesticTabItem :items="rowItem[1]" v-if="!!rowItem[1]" />
          </div>
        </div>

        <div class="col-lg-4" v-if="rowIndex % 2 === 0">
          <div class="row">
            <DomesticTabItem :items="rowItem[1]" v-if="!!rowItem[1]" />
            <DomesticTabItem :items="rowItem[2]" v-if="!!rowItem[2]" />
          </div>
        </div>

        <div class="col-lg-8" v-else>
          <div class="row">
            <DomesticTabItem :items="rowItem[2]" :isBig="!!rowItem[2]" v-if="!!rowItem[2]" />
          </div>
        </div>

      </div>
    </div>

    <div class="lesiurpackages_box" ref="show-more-line" :class="false && 'Oregenizedbox'" v-else>
      <div class="row" v-for="(rowItem, rowIndex) in rowGroup" :key="rowIndex">
        <div
          :class="rowItem.length === 1 ? 'col-lg-12' : 'col-lg-4'"
          v-if="rowIndex % 2 === 0"
        >
          <div class="row">
            <LesiurtabItem :items="rowItem[0]" />
            <LesiurtabItem :items="rowItem[1]" v-if="!!rowItem[1]" />
          </div>
        </div>
        <div class="col-lg-8" v-else>
          <div class="row">
            <LesiurtabItem :items="rowItem[0]" :isBig="device === 'mobile' ? false:!!rowItem[2]" />
          </div>
        </div>

        <div class="col-lg-8" v-if="rowIndex % 2 === 0">
          <div class="row">
            <LesiurtabItem :items="rowItem[2]" v-if="!!rowItem[2]" :isBig="device === 'mobile' ? false: true" />
          </div>
        </div>

        <div class="col-lg-4" v-else>
          <div class="row">
            <LesiurtabItem :items="rowItem[1]" v-if="!!rowItem[1]" />
            <LesiurtabItem :items="rowItem[2]" v-if="!!rowItem[2]" />
          </div>
        </div>
      </div>
    </div>
    <div class="lesiurpackages_box mt-0" v-if="dealListItem.code === 'vacation_pack' && !isMore && state">
      <ContentLoading :type="device === 'mobile' ? 'mobile-deal':'deal'" />
    </div>
    <div ref="show-more-line"></div>
  </div>
</template>
<script>
import TemplateLesiurTabContent from './TemplateLesiurtabContent';

export default {
  name: 'tabContentTheme0',
  mixins: [TemplateLesiurTabContent],
  components: {
    LesiurtabItem: () => import('@/components/home/lesiurtabItem/LesiurtabItemTheme0'),
    DomesticTabItem: () => import('@/components/home/LesiurtabItemForDomestic'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
};
</script>
<style scoped>
.loadMore {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.loadMore > button {
  padding: 10px 24px;
  border-radius: 50px;
  border: 1px solid rgb(0, 97, 171);
  border: 1px solid rgba(var(--theme-primary));
  cursor: pointer;
}
</style>
